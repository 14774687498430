import type { Attr } from "~/types";

export default async function () {
  const store = useState<Attr[]>("attr", () => []);

  if (0 === store.value.length) {
    const res = await useFetch("/api/sekou/attr");
    if (res && res.data && res.data.value) {
      store.value = res.data.value;
    }
  }
  return store;
}
